//
// Breadcrumb custom
// --------------------------------------------------

// Breadcrumb Classic
// -------------------------

.breadcrumb-classic {
  position: $breadcrumb-classic-position;

  &{
    @include bg-variant-custom(linear-gradient(90deg, $picton-blue 0%, $dollar-bill 100%), $picton-blue);
  }

  &:before{
    @include bg-variant-custom(linear-gradient(270deg, $picton-blue 0%, $dollar-bill 100%), $picton-blue);
  }

  &, a {
    color: $breadcrumb-classic-color;
  }

  &:before {
    position: $breadcrumb-classic-after-position;
    top: $breadcrumb-classic-after-position-right;
    left: $breadcrumb-classic-after-position-left;
    width: $breadcrumb-classic-after-width;
    height: $breadcrumb-classic-after-height;
    content: '';
    animation: $breadcrumb-classic-animation;
  }

  & > *:not(svg) {
    position: relative;
    z-index: 1;
  }

  .svg-triangle-bottom{
    position: absolute;
    top: 100%;
    left: 50%;
    width: 60px;
    height: 30px;
    margin-top: -1px;
    margin-left: -30px;
    z-index: 2;
  }
}

// Breadcrumb Default
// -------------------------

.breadcrumb-default {
  padding: $breadcrumb-default-inset;
  background: $breadcrumb-default-background;

  > li{
    display: inline-block;

    a{
      color: $breadcrumb-default-color;
      font-size: $breadcrumb-default-size;

      &:hover {
        color: $breadcrumb-default-hover;
      }
    }

    & + li {
      margin-left: $breadcrumb-default-offset-left;

      &:before {
        content: $breadcrumb-default-icon;
        font-family: $breadcrumb-default-icon-font-family;
        color: $breadcrumb-default-icon-color;
        padding-right: $breadcrumb-default-icon-inset-right;
      }
    }
  }
}

// Breadcrumb Modern
// -------------------------

.breadcrumb-modern {
  color: $gray-300;
  li{
    color: $primary;
    &:last-child{
      color: $gray-300;
    }
  }
}

// Bootstrap Breadcrumb
// -------------------------

.breadcrumb{

}


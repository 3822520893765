//
// RD Navbar Sidebar Toggle Panel
// --------------------------------------------------

%rd-navbar-sidebar-toggle-panel{
  .rd-navbar-panel{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 16;
  }

  &, .rd-navbar-panel{
    height: $rd-navbar-fixed-height;
  }

  .rd-navbar-toggle {
    position: fixed;
    z-index: 17;
    left: 4px;
    top: 4px;
    @extend %rd-navbar-transition;
  }

  @include media-breakpoint-up(xl) {
    .rd-navbar-panel{
      position: static;
    }
    &, .rd-navbar-panel{
      height: 0;
    }
    .rd-navbar-toggle{
      background: none;
      border: none;
      outline: none;
      padding: 0;
      top: 48px;
      left: 40px;
      @include toggle-icons-via-rotation($rd-navbar-sidebar-toggle-panel-lg-toggle-width, $rd-navbar-sidebar-toggle-panel-lg-toggle-size, $rd-navbar-sidebar-toggle-panel-lg-toggle-color, $rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-before, $rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-after, $rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-family);
      text-shadow: 0 0 1px $black;
      &.active{
        left: 355px;
      }
    }
  }
}

//
// Dark Theme
// ------------------------------------

%rd-navbar-sidebar-toggle-panel-dark{
  .rd-navbar-panel {
    background: $rd-navbar-dark-sidebar-toggle-panel-background;
    box-shadow: nth($shadow-area-md, 1) nth($shadow-area-md, 2) nth($shadow-area-md, 3) nth($shadow-area-md, 4) #000;
  }

  @include media-breakpoint-down(lg) {
    .rd-navbar-toggle{
      @include toggle-lines-to-arrow(48px, 24px, $white);
    }
  }

  @include media-breakpoint-up(xl) {
    .rd-navbar-panel{
      background-color: transparent;
      box-shadow: none;
    }
  }
}

//
// Light Theme
// --------------------------------------------------

%rd-navbar-sidebar-toggle-panel-light{
  .rd-navbar-panel {
    background: $rd-navbar-light-sidebar-toggle-panel-background;
    box-shadow: nth($shadow-area-md, 1) nth($shadow-area-md, 2) nth($shadow-area-md, 3) nth($shadow-area-md, 4) #000;
  }
  .rd-navbar-toggle {
    color: $rd-navbar-light-sidebar-toggle-panel-color;
  }

  @include media-breakpoint-down(lg) {
    .rd-navbar-toggle{
      @include toggle-lines-to-arrow($rd-navbar-light-sidebar-toggle-panel-lg-toggle-width, $rd-navbar-light-sidebar-toggle-panel-lg-toggle-size, $rd-navbar-light-sidebar-toggle-panel-lg-toggle-color);
    }
  }

  @include media-breakpoint-up(xl) {
    .rd-navbar-panel{
      background-color: transparent;
      box-shadow: none;
    }
  }
}
/*
*
* Video BG
* --------------------------------------------------
*/

.bg-vide {
  position: relative;
  z-index: 0;

  > * {
    position: relative;
    z-index: 1;
  }
}

/*
* @subsection Google Map
* @description Describes style declarations for RD Google Map extension
* @version     1.0.0
*/
$map-height:    300px;
$map-xs-height: 350px;
$map-sm-height: 710px;

.google-map-markers{
  display: none;
}

.google-map-container {
  width: 100%;
}

.google-map {
  height: $map-height;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: $map-xs-height;
  }

  @include media-breakpoint-up(md) {
    height: $map-sm-height;
  }
}

.google-map-container-1 {
  .google-map {
    height: $map-xs-height;

    @include media-breakpoint-up(md) {
      height: 400px;
    }
  }
}

.google-map-container-2 {
  .google-map {
    height: $map-xs-height;

    @include media-breakpoint-up(md) {
      height: 493px;
    }
  }
}

.gm-style-iw > div > div {
  padding-right: 10px;
  font-size: 13px;
}

// Vertical align
.google-map-align {
  display: flex;
  min-height: 330px;
  height: auto;

  .google-map { height: auto; }
}

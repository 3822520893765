//
// Offsets
// --------------------------------------------------


// Insets
// -------------------------

$insets: (0, 15px, 20px, 30px, 40px, 50px, 80px, 93px, 100px, 115px);

html .page{
  @include indent-responsive(inset, left, padding-left, $grid-breakpoints, $insets);
  @include indent-responsive(inset, right, padding-right, $grid-breakpoints, $insets);
}

.inset-lg {
  padding: 30px;
}


// Elements
// -------------------------

.inset-left-7p{
  padding-left: 7%;
}
.inset-right-7p{
  padding-right: 7%;
}

.inset-left-11p{
  padding-left: 11%;
}

.inset-right-11p{
  padding-right: 11%;
}


// Grid
// -------------------------


// Sections
// -------------------------


// Responsive
// -------------------------

$offsets: (0, 4px, 10px, 14px, 20px, 24px, 30px, 34px, 41px, 50px, 66px, 98px, 110px, 124px);


html .page{
  @include indent-responsive(offset, top, margin-top, $grid-breakpoints, $offsets);
}

// Range spacing
html {
  .row-0 { @include grid-offset(0px); }
  .row-24 { @include grid-offset(24px); }
  .row-30 { @include grid-offset(30px); }
  .row-50 { @include grid-offset(50px); }
  .row-60 { @include grid-offset(60px); }

  @include media-breakpoint-up(lg) {
    .row-md-30 { @include grid-offset(30px); }
    .row-lg-50 { @include grid-offset(50px); }
  }
}
//
// RD Navbar Sidebar Toggle
// --------------------------------------------------

.rd-navbar-sidebar-toggle {
  &.rd-navbar-fixed {
    @extend %rd-navbar-default-mobile-menu;
    @extend %rd-navbar-default-cart-aside-right;
    @extend %rd-navbar-default-aside-left;
    @extend %rd-navbar-default-mobile-search;
    @extend %rd-navbar-sidebar-toggle-panel;
    @extend %rd-navbar-sidebar-toggle-footer;

    .rd-navbar-nav-wrap{
      height: 100%;
      overflow: auto;
      bottom: auto;
    }

    .rd-navbar-mobile-scroll-holder {
      display: flex;
      flex-direction: row;
    }

    .rd-navbar-mobile-scroll {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      justify-content: space-between;
    }
    .rd-navbar-nav {
      flex: 2 1 auto;
    }

    @include media-breakpoint-up(xl) {
      .rd-navbar-nav-wrap {
        width: $rd-navbar-sidebar-toggle-lg-toggle-width;
        padding: $rd-navbar-sidebar-toggle-lg-toggle-padding;
      }

      .rd-navbar-mobile-brand {
        margin-top: $rd-navbar-sidebar-toggle-lg-toggle-mobile-brand-offset-top;
      }

      .form-search-wrap {
        margin-top: $rd-navbar-sidebar-toggle-lg-toggle-form-search-offset-top;
      }

      .rd-navbar-mobile-scroll{
          padding: $rd-navbar-sidebar-toggle-lg-mobile-scroll-padding;
        overflow-y: hidden;
        max-height: inherit;
      }

      .rd-navbar-nav {
        margin-top: $rd-navbar-sidebar-toggle-lg-toggle-offset-top;
      }
      .rd-navbar-megamenu {
        padding-left: 0;
      }
      .resp-tab-item{
        display: none;
      }
    }
  }

  // Themes

  //  Dark Theme
  // -------------------

  &.rd-navbar-dark {

    // Mobile Fixed Layout Colors
    &.rd-navbar-fixed {
      @extend %rd-navbar-sidebar-toggle-panel-dark;
      @extend %rd-navbar-default-mobile-menu-dark;
      @extend %rd-navbar-default-cart-aside-right-dark;
      @extend %rd-navbar-default-aside-left-dark;
      @extend %rd-navbar-default-mobile-search-dark;
      @extend %rd-navbar-sidebar-toggle-footer-dark;
      @include media-breakpoint-up(xl) {
      }
    }
  }

  // Light Theme
  // -------------------

  &.rd-navbar-light {

    .rd-navbar-search-toggle, .rd-navbar-cart {
      span {
        color: $rd-navbar-sidebar-toggle-search-toggle-cart-color;
      }
    }

    // Mobile Fixed Layout Colors
    &.rd-navbar-fixed {
      @extend %rd-navbar-sidebar-toggle-panel-light;
      @extend %rd-navbar-default-mobile-menu-light;
      @extend %rd-navbar-default-cart-aside-right-light;
      @extend %rd-navbar-default-aside-left-light;
      @extend %rd-navbar-default-mobile-search-light;
      @extend %rd-navbar-sidebar-toggle-footer-light;

      @include media-breakpoint-up(xl) {
      }
    }
  }
}